<template>
  <Mpopup v-model="show">
    <div class="kd-import" :style="{height: height + 'px'}">
      <div class="kd-import__swiper" :duration="300" :style="{transform: translate}">
        <div class="kd-import__swiper-item">
          <div class="kd-import__wrap">
            <div class="kd-import__title">批量导入</div>
            <div class="kd-import__content">
              <div class="kd-import__desc">粘贴整段地址，自动识别姓名、电话、地址，每个地址之间分行显示，可批量识别20条</div>
              <div class="kd-import__textarea-wrap">
                <!-- <span v-if="!content" class="kd-import__placeholder">
                  如：张三，13800138000 广东省深圳市南山区科技南十二路金蝶软件园<br/>王五，13800138000 广东省深圳市南山区科技南十二路金蝶软件园
                </span> -->
                <textarea v-model="content" maxlength="-1" style="width: 100%;height: 100%;color: #333;font-size: 0.9286rem;line-height: 1.2857rem;" :enableNative="false" 
                :placeholder="placeholder"/>
                <div v-if="content" class="kd-import__clear" @click.stop="clear">清空</div>
              </div>
            </div>
            <div class="kd-import__text-wrap" :style="{visibility: showLoading || list.length ? 'visible' : 'hidden'}">
              <span class="kd-import__text" bindtap="goNext">{{ showLoading ? '正在识别' : '识别出' + list.length + '个地址' }}</span>
              <span class="kd-import__loading" :style="{display: showLoading ? 'inline-block' : 'none'}" />
              <span class="kd-import__next" :style="{display: !showLoading ? 'inline-block' : 'none'}" />
            </div>
            <div class="kd-import__button" @click="goNext">下一步</div>
          </div>
        </div>
        <div class="kd-import__swiper-item">
          <div class="kd-import__wrap">
            <div class="kd-import__title">批量导入</div>
            <div class="kd-import__prev" @click="goPrev">返回上一页</div>
            <div class="kd-import__list">
              <div style="overflow-y: auto">
                <div v-for="(item, index) in list" :key="index" class="kd-import__item">
                  <div class="kd-import__info">
                    <div class="kd-import__name">{{ item.name }} {{ item.mobile || item.tel }}</div>
                    <div class="kd-import__detail">{{ item | address }}</div>
                  </div>
                  <div class="kd-import__edit" @click="edit(index)" />
                </div>
              </div>
            </div>
            <div class="kd-import__ctrl">
              <div class="kd-import__ctrl-text">保存到地址库</div>
              <div class="kd-import__save-button" :class="{active :saveCtrl}" @click="toggleSave" />
            </div>
            <div class="kd-import__text-wrap">
              <span class="kd-import__text">识别出{{ list.length }}个地址</span>
            </div>
            <div class="kd-import__button" @click="finish">完成</div>
          </div>
        </div>
        <div class="kd-import__swiper-item">
          <div class="kd-import__title">修改收件人</div>
          <div class="kd-import__prev" @click="goPrev">返回上一页</div>
          <!-- <address-item :item.sync="detail" :auto-fit="autoFit" :custom-style="'height: '+(height - 140)+'rpx'" force-save-type="unsave" :recgnize-text="recgnizeText" @save="save" /> -->
          <AddressItem :detailProp.sync="detail" v-if="page===2" @save="save" />
        </div>
      </div>
    </div>
  </Mpopup>
</template>
<script>
function generateUuid(len = 16) {
  const seeds = 'abcdefghijklmnopqrstuvwxyz'
  const arr = seeds.split('').concat(seeds.toUpperCase().split('')).concat('0123456789'.split(''))
  let m = arr.length; let i
  while (m) {
    i = Math.floor(Math.random() * m--)
    const temp = arr[m]
    arr[m] = arr[i]
    arr[i] = temp
  }
  return arr.slice(0, len).join('')
}
// 手机号正则
const mobileReg = /^1\d{10}$/

import Mpopup from "components/Mpopup/Mpopup"
import AddressItem from "components/AddressItem/AddressItem"
export default {
  components: {
    Mpopup,
    AddressItem
  },
  props: {
    value: Boolean,
    height: {
      type: Number,
      default: 490
    }
  },
  filters: {
    address: function(item) {
      return item.province + item.city + item.district + item.addr
    }
  },
  data() {
    return {
      show: false,
      showEdit: false,
      detail: {},
      list: [],
      saveCtrl: true,
      page: 0,
      showLoading: false,
      content: '',
      autoFit: true,
      placeholder: "如：张三，13800138000 广东省深圳市南山区科技南十二路金蝶软件园\n王五，13800138000 广东省深圳市南山区科技南十二路金蝶软件园",
      update: 0
    }
  },
  computed: {
    translate: function() {
      return `translate3d(-${100 * this.page}vw, 0,0)`
    }
  },
  watch: {
    content: function(val) {
      this.content = this.content.trim()
      this.recognize()
    },
    value: function(val) {
      this.show = val
    },
    show: function(val) {
      if (val !== this.value) this.$emit('input', val)
      if (!val) this.goItem(0)
      this.autoFit = !val
    }
  },
  created() {
    this.show = this.value
  },
  methods: {
    goItem(count) { // 跳转到对应页面
      this.page = count
    },
    goPrev() { // 返回上一页
      this.page--
    },
    goNext() {
      if (!this.value) return this.$toast('请输入要识别的内容')
      if (this.showLoading) return
      if (!this.list.length) return this.$toast('没有识别到地址')
      this.goItem(1)
    },
    toggleSave() { // 保存到地址库按钮切换
      this.saveCtrl = !this.saveCtrl
    },
    edit(index) {
      this.editIndex = index
      // this.detail = this.list[index]
      // Object.assign(this.detail, this.list[index])
      this.detail = JSON.parse(JSON.stringify(this.list[index]))
      // this.update++
      // console.log(this.update);
      this.recgnizeText = this.list[index].content
      this.goItem(2)
    },
    save(data) {
      this.list.splice(this.editIndex, 1, data)
      this.goItem(1)
    },
    clear() { // 清空内容
      this.content = ''
      this.list = []
    },
    recognize() { // 识别内容
      if (!this.content) return
      clearTimeout(this.recgnizeTimer)
      this.showLoading = true
      this.recgnizeTimer = setTimeout(() => {
        this.editIndex = -1
        this.list = []
        this.$http.post('/apicenter/order.do?method=infosplit4Batch', {
          data: {
            content: this.content
          },
          needAuth: true
        }).then(res => {
          this.setList(res.data)
        }).finally(() => {
          this.showLoading = false
        })
      }, 1000)
    },
    setList(data) { // 设置识别结果
      this.uuidMap = {}
      const res = data.map((item, index) => {
        const obj = {}
        const mobile = item.mobile && item.mobile[0] || ''
        const xzq = item.xzq || {}
        const uuid = generateUuid()
        obj.name = item.name
        obj.mobile = mobileReg.test(mobile) ? mobile : ''
        obj.phone = obj.mobile
        obj.tel = obj.mobile ? '' : mobile
        obj.addr = item.addr
        obj.address = obj.addr
        obj.province = xzq.province || ''
        obj.city = xzq.city || ''
        obj.district = xzq.district || ''
        obj.xzqName = `${obj.province},${obj.city},${obj.district}`
        obj.content = item.content
        obj.reqAddressId = uuid
        this.uuidMap[uuid] = index
        return obj
      })

      this.list = res
    },
    getList(data) {
      const uuidMap = this.uuidMap
      const list = this.list
      for (let i = 0; i < data.length; i++) {
        const key = data[i].reqAddressId
        list[uuidMap[key]].id = data[i].id
      }
      return list
    },
    finish() {
      if (this.saveCtrl) {
        const loading = this.$loading('正在保存...')
        this.$http.post('/apicenter/kdmkt.do?method=saveAddressBookBatch', {
          data: {
            addrListJson: JSON.stringify(this.list)
          },
          needAuth: true
        }).then(res => {
          this.list = this.getList(res.data)
          this.doFinish(1)
        }).finally(() => {
          // this.$hideLoading()
          loading.hide()
        })
      } else {
        this.doFinish(0)
      }
    },
    doFinish(save) {
      this.$emit('finish', {
        list: this.list,
        hasSave: !!save
      })
      setTimeout(() => {
        this.goItem(0)
      }, 1000)
      this.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
.kd-import {
  box-sizing: border-box;
  overflow: hidden;
  &__swiper {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    width: 300vw;
    transition: transform .3s;
  }
  &__swiper-item {
    width: 100vw;
    box-sizing: border-box;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__title {
    padding: 1.0714rem 1.5rem;
    font-size: 1.2143rem;
    font-weight: bold;
    color: #333;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    padding: 0 1.5rem;
  }
  &__desc {
    color: #888;
    font-size: .9286rem;
  }
  &__button {
    width: 89.2vw;
    margin: .8571rem auto;
    background: #ff7f02;
    border-radius: .2143rem;
    color: #FFF;
    line-height: 3.1429rem;
    font-size: 1.2143rem;
    text-align: center;
  }
  &__textarea-wrap {
    position: relative;
    flex: 1;
    margin-top: .9286rem;
    padding: 1.1429rem .5714rem 2.5rem;
    background: #efeff4;
    box-sizing: border-box;
    height: 0;
  }
  &__clear {
    position: absolute;
    bottom: .3929rem;
    right: 0;
    padding: .3571rem .8571rem;
    color: #317ee7;
    font-size: .9286rem;
  }
  &__placeholder {
    position: absolute;
    left: .5714rem;
    right: .5714rem;
    top: .7143rem;
    font-size: .9286rem;
    color: #bebebe;
  }
  &__text-wrap {
    padding-top: .3571rem;
    margin-bottom: -0.3571rem;
    text-align: center;
    color: #888;
    font-size: .8571rem;
    .next {
      display: inline-block;
      vertical-align: -0.1429rem;
      width: .4643rem;
      height: .8571rem;
      margin-left: .3571rem;
      background: url(https://cdn.kuaidi100.com/images/wxapp/icons/ico_next.png) center / 100% no-repeat;
    }
  }
  &__loading {
    display: inline-block;
    vertical-align: -0.4286rem;
    width: 1.4286rem;
    height: 1.4286rem;
    margin-left: .3571rem;
    background: url(https://cdn.kuaidi100.com/images/wxapp/icons/ico_loading.gif) center / 100% no-repeat;
  }
  &__list {
    flex: 1;
    position: relative;
    margin-top: .3929rem;
    border-top: 1px solid #eee;
    overflow: auto;
    scroll-view {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.2857rem;
    margin: 0 1.5rem;
    border-bottom: 1px solid #eee;
  }
  &__info {
    width: 16.5rem;
  }
  &__name {
    font-size: 1rem;
    color: #333;
    font-weight: bold;
  }
  &__detail {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: .8571rem;
    color: #888;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__edit {
    width: 10vw;
    height: 3.5714rem;
    background: url(https://cdn.kuaidi100.com/images/wxapp/icons/ico_edit.png) right center / 1.1429rem auto no-repeat;
  }
  &__ctrl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .7143rem 1.5rem;
    color: #888;
    font-size: .9286rem;
  }
  &__save-button {
    position: relative;
    width: 2.1429rem;
    height: 1.1429rem;
    border: 1px solid #dedede;
    background: #efeff4;
    border-radius: 1.0714rem;
    transition: all .2s;
    overflow: hidden;
    &::before {
      position: absolute;
      left: 0;
      top: 1px;
      width: 1.0714rem;
      height: 1.0714rem;
      border-radius: 50%;
      background: #FFF;
      transition: all .3s;
      content: '';
      z-index: 2;
    }
    &::after {
      position: absolute;
      left: 2px;
      top: 0;
      bottom: 0;
      right: 1px;
      background: #efeff4;
      border-radius: 1.0714rem;
      transition: all .3s;
      content: '';
    }
    &.active {
      background: #3DDF27;
      border-color: #3DDF27;
      &::before {
        width: 1rem;
        height: 1rem;
        top: .1071rem;
        left: 1.0714rem;
        box-shadow: none;
      }
      &::after {
        transform: scale(0);
      }
    }
  }
  &__prev {
    margin-left: 1.5rem;
    color: #317ee7;
    font-size: .9286rem;
    &::before {
      display: inline-block;
      vertical-align: -0.1429rem;
      width: 0.5rem;
      height: .8571rem;
      background: url(https://cdn.kuaidi100.com/images/wxapp/icons/ico_next.png) center / 100% no-repeat;
      margin-right: .3571rem;
      margin-left: 0;
      transform: rotate(180deg);
      content: "";
    }
  }
}
</style>