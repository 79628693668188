import { mapActions, mapState, mapGetters } from 'vuex'
import InfoSelect from "components/InfoSelect/InfoSelect"
import Modal from "components/Modal/Modal"
import Mpopup from "components/Mpopup/Mpopup"
import ProtocolCom from "../Index/components/protocolCom.vue"
import Comment from "../Index/components/comment.vue"
import BatchImport from "components/Import/Import"
import Picker from 'vant/lib/picker';
import 'vant/lib/picker/style';
import TopNav from "components/TopNav/TopNav"
export default {
  name: "dispatchbatch",
  components: {
    InfoSelect,
    Modal,
    Mpopup,
    ProtocolCom,
    Comment,
    BatchImport,
    Picker,
    TopNav
  },
  data() {
    return {
      orderSource: 'orderindex',
      islogin: false,
      serviceTimeTipsShow: false,
      serviceTimeTips: "",
      sendman:{},
      recman: [],
      showImport: false,
      isResetLoc: false,
      address2geoData: '',
      chooseComIndex: [],
      availableCom4BrandList: [],
      isShowNewCom: false,
      isShowLoading: false,
      isShowSmallLoading: false,
      nowCom: {},
      orderData: {
        cargo: '',
        weight: 1,
        cargoInput: '',
        picurl: '',
        cargoImgUrl: '',
        cargoDesc: [],
        comment: '',
        doortime: '',
        switchChecked: false
      },
      isExistMkt: false,
      columns: [],
      doorTime: [],
      doordate: [],
      doorTimelist: {},
      indexArry: [0, 0],
      nightTips: '',
      orderDataBackup: {},
      oldcargoDesc: [],
      showCargo: false,
      cargoList: [{ name: '日用品', data: ['含液体', '含电池'] },
        { name: '服饰' },
        { name: '文件', data: ['含法律公文'] },
        { name: '食品', data: ['含液体'] },
        { name: '证件' },
        { name: '数码家电', data: ['含电池'] },
        { name: '其他', data: ['含液体', '含电池'] }
      ],
      isShowBj: false,
      priceInfo: {},
      priceNum: 4,
      choosePaywayIndex: 0, // 寄付 到付
      commentModal: false,
      doorModal: false,
      protocolMeta: {
        agree: false,
        show: false,
        type: 1
      },
      payDetailModal: false,
      queryFirstKuaidComData: {}, // 预派单
      isShowDBP: false,
      night: false,
      showOpenAlipay: false,//开通支付宝弹窗
      showAlipayBack: false,//支付宝返回显示弹窗
      showALipayFailTips: false,//开通失败弹窗
      alipayData: {
        alipaystatus: 0, // 是否签约
        alipayBtn: true // 是否勾选
      },
      generateUuidData: '',
    }
  },
  computed: {
    ...mapState({
      locationInfo: state => state.position || {},

    }),
    newComName () {
      return this.nowCom.name || ''
    },
    paywayText() {
      let text = ''
      if (this.nowCom) {
        const payway = this.nowCom.payway
        if (payway === 0) { // 优选
          text = '在线支付'
        } else if (payway === 1) { // 线下
          text = this.choosePaywayIndex === 0 ? '寄付' : '到付'
        } else if (payway === 3){ // 线上
          text = '支付宝免密支付'
        }
      }
      return text
    },
    nowList() { // 勾选的公司
      const list = []
      for(let i of this.chooseComIndex) {
        list.push(this.availableCom4BrandList[i])
      }
      return list
    },
    nowComId() {
      return this.nowCom.comService || ''
    },
    recmanIds() {
      return this.recman.map(item => item.id)
    },
    chooseCargoIndex() {
      let index
      for (let i = 0; i < this.cargoList.length; i++) {
        if (this.cargoList[i].name === this.orderData.cargo) {
          index = i
        }
      }
      return index || 0
    },
    new_lon() {
      return this.address2geoData ? this.address2geoData.split(',')[0] : ''
    },
    new_lat() {
      return this.address2geoData ? this.address2geoData.split(',')[1] : ''
    },
    newColumns () {
      let list = this.columns
      for (let i=0; i<this.doorTime.length; i++) {
        if (this.doorTime[i].itemName == 2) {
          if (this.isExistMkt) {
            list[1].values[i] = this.doorTime[i].itemValue + (this.doorTime[i].descr || '')
          } else if (!this.isExistMkt && this.nowCom.kdbest === 'Y') {
            list[1].values[i] = this.doorTime[i].itemValue + '约满'
          }
        }
      }
      return list
    },
  },
  mounted() {
    
  },
  created() {
    this.login(() => {
      console.log('登录了')
      this.islogin = true
      this.alipaysignstatus()
    })
  },
  activated() {
    
  },
  methods: {
    generateUuid() {
      let seeds = 'abcdefghijklmnopqrstuvwxyz'
      let arr = seeds.split("").concat(seeds.toUpperCase().split("")).concat('0123456789'.split(""))
      let m = arr.length, i
      while (m) {
        i = Math.floor(Math.random() * m--)
        let temp = arr[m]
        arr[m] = arr[i]
        arr[i] = temp
      }
      this.generateUuidData = arr.slice(0, 16).join("")
    },
    loginCallback () {
      this.islogin = true
    },
    showSelectContact (type, index) { // 选择地址
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast()
      const detail = type === 'sendman' ? this[type] : this.recman[index]
      this.$router.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, detail),
          source: "dispatch",
          tag: type
        }
      })
    },
    showContact(type) {
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast() // 接收地址填充的通知
      this.$router.push({
        name: "addressList",
        params: {
          source: "dispatch"
        },
        query: {
          from: "dispatch",
          tag: type,
          selectId: this[type].id || -1
        }
      })
    },
    broadcast() {
      this.$broadcastChannel.$on("fillContact", (addr, type) => {
        this.selectContact(addr, type)
        this.$broadcastChannel.$off("fillContact")
      })
    },
    async selectContact(data, type) {
      if (!data) return
      if (/台湾|香港|澳门/.test(data.xzqName) && type == 'sendman') {
        this.$confirm({
          content: "抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！",
          type: 'alert',
          confirmText: "我知道了"
        })
        return
      }
      if(Array.isArray(data)) {
        for(let item of data) {
          if(item.address.length < 4) {
            return this.$toast("详细地址不能少于4个字")
          }
        }
      }
      else if(data.address.length < 4) {
        return this.$toast("详细地址不能少于4个字")
      }
      this.isResetLoc = false
      if (type === 'sendman') {
        // Object.assign(this[type], data)
        this[type] = data
        await this.address2geo() // 修改寄件人 去查地址经纬度
        localStorage.setItem('dispatch_sendman', JSON.stringify(data)) 
      }
      else {
        if(Array.isArray(data)) {
          if(this.recman.length) {//去重添加
            data.forEach((item) => {
              if(!this.recmanIds.includes(item.id)) {
                this.recman.push(item)
              }
            })
          }
          else {
            this.recman = [...data]
          }
        }
        else { // 修改收件人信息
          if(this.recmanIds.includes(data.id)) {
            const index = this.recmanIds.indexOf(data.id)
            this.recman.splice(index, 1, data)
            // this.recman = [...data]
          }
          else {
            this.recman = [...this.recman, data]
          }
        }
        this.autoChooseGoods()
      }
      if(this.sendman.name && this.recman.length) {
        this.availableCom4Brand('popup')
      }
    },
    async address2geo () { // 下单优化 检测经纬度
      let url = '/apicenter/xcx.do?method=address2geo'
      const params = {
        data: {
          address: this.sendman.xzqName.split(',').join() + this.sendman.address
        }
      }
      await this.$http.post(url, params).then((res)=> {
        this.address2geoData = res.data
        if(this.address2geoData) { // 如果切换寄件地址了，将寄件地址的经纬度更新
          this.sendman.longitude = this.address2geoData.split(',')[0]
          this.sendman.latitude = this.address2geoData.split(',')[1]
          this.locationInfo.lat = this.sendman.latitude
          this.locationInfo.lng = this.sendman.longitude
          this.$store.commit("setLocate", this.locationInfo)
          
        }
        if (!this.address2geoData) { // 检测不到经纬度
          // this.searchAddress()
        }
      })
    },
    handleFinish(e) { // 批量导入地址回调
      this.setRecers(e.list)
      this.showImport = false
      this.autoChooseGoods()
      if(this.sendman.name && this.recman.length) {
        this.availableCom4Brand('popup')
      }
      // if (e.hasSave) { // 批量保存到了地址库，需要更新地址库
      //   // this.$refs['addrList'].refresh() // 刷新地址库
      // }
    },
    setRecers(list) {
      this.recman = [...this.recman, ...list]
    },
    selectCargo(index) {
      this.nowCargoIndex = index
      const d = this.recman[index]
      this.orderData.cargo = d.orderData.cargo || '日用品'
      this.orderData.cargoInput = d.orderData.cargoInput || ''
      this.orderData.cargoDesc = d.orderData.cargoDesc || [],
      this.orderData.weight = d.orderData.weight || 1
      this.orderData.cargoImgUrl = d.orderData.cargoImgUrl || ''
      this.orderDataBackup = this.clone(this.orderData)
      this.oldcargoDesc = this.clone(this.orderData.cargoDesc)
      this.showCargo = true
    },
    cancelCargo () { // 取消物品弹窗
      this.orderData = this.orderDataBackup
    },
    goUrl () {
      this.$router.push({
        name: "cargoInfo"
      })
    },
    chooseCargo (item) { // 点击物品类型
      this.orderData.cargoDesc = []
      this.orderData.cargo = item.name
    },
    selectCargoDesc (item, index) { // 物品类型 带液体等 打勾
      if (this.orderData.cargoDesc.indexOf(item) > -1) {
        this.orderData.cargoDesc.splice(this.orderData.cargoDesc.indexOf(item), 1)
      } else {
        this.orderData.cargoDesc.push(item)
      }
    },
    adjustWeight (tag) { // 调整重量
      if (tag === 'add') {
        this.orderData.weight = this.orderData.weight > 19 ? this.orderData.weight : this.orderData.weight + 1
      } else {
        this.orderData.weight = this.orderData.weight < 2 ? this.orderData.weight : this.orderData.weight - 1
      }
    },
    chooseBj (index) { // 选择是否保价
      this.orderData.chooseBjIndex = index
    },
    toggleValinsAgree () { // 保价协议 打勾
      if(this.orderData.agreeValins) {
        this.orderData.agreeValins = false
        localStorage.removeItem('dispatch_agreeValins')
      } else {
        this.valinsProtocolMeta.show = true
      }
    },
    confirmCargo() { // 物品信息弹窗 完成
      const index = this.nowCargoIndex
      if (this.orderData.cargo === '其他' && !this.orderData.cargoInput) {
        this.$toast('请输入物品名称')
        return
      }
      if (isNaN(this.orderData.weight) || (this.orderData.weight < 1 || this.orderData.weight > 20)) {
        this.$toast('物品重量：请输入1-20之间的整数')
        return
      }
      if (this.orderData.cargo !== '其他') {
        this.orderData.cargoInput = ''
      }
      this.recman[index].orderData = this.clone(this.orderData)
      this.showCargo = false
      if (this.orderData.switchChecked) {
        // this.autoChooseGoodsAll(this.orderData)
      }
      if (this.orderData.cargoDesc.length > 0 && this.availableCom4BrandList.length > 0 || this.orderData.cargo === '证件') { // 有特殊物品
        if (!this.oldcargoDesc.includes(this.orderData.cargoDesc) || this.orderData.cargo === '证件') {
          this.$toast('包含特殊物品，请重新选择快递公司')
          this.availableCom4Brand()
          this.isShowNewCom = true
        }
      } else if (this.availableCom4BrandList.length > 0) { // 无特殊物品
        this.availableCom4BrandNew()
      }
    },
    goAddress(type) {
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast() // 接收地址填充的通知
      this.$router.push({
        name: "addressList",
        params: {
          source: "dispatch"
        },
        query: {
          from: "dispatch",
          tag: type,
          selectId: -1,
          isBatch: 1,
          selectIds: this.recmanIds.join(',')
        }
      })
    },
    async delContact(index) { // 删除已选择的收件人
      const list = this.recman
      const cacheId = this.recmanIds
      const item = list.splice(index, 1)[0]
      this.recman = list

      // if (this.priceInfo.length > 0) {
      //   const list1 = this.priceInfo
      //   this.priceInfo = list1
      // }

      // uni.setStorageSync('lastBatchRecers', list) // 更新选中的缓存
      // const findIndex = cacheId.indexOf(item.id)
      // if (findIndex !== -1) {
      //   cacheId.splice(findIndex, 1)
      //   this.contactMetas.recmanIdsCache = cacheId
      // }
      if (this.recman.length === 0) {
        this.clearAll()
      } else if (this.priceInfo.length > 0) {
        await this.availableCom4BrandNew()
        this.clearDoorTime()
        await this.getDoorTimeList()
        this.confirmDoor('dontGetPrice')
      }
      // this.refreshBottomTipPrice()
      // this.availableCompList(this.recman.length + this.batchOrderCount)
    },
    clearAll() {
      this.priceInfo = []
      this.nowCom = {}
      this.orderData.doortime = ''
      this.availableCom4BrandList = []
    },
    checkModify(data, type) {
      const origin = type === 'send' ? this.sendman : this.recman
      const keys = ['province', 'district', 'city', 'addr']
      for (let i = 0; i < keys.length; i++) {
        if (origin[keys[i]] + '' != data[keys[i]]) {
          return true
        }
      }
      return false
    },
    autoChooseGoods(tag) { // 自动选择物品
      const list = this.recman
      for (const i of list) {
        if (!i.orderData || tag === 'all') {
          i.orderData = {}
          i['orderData'].cargo = '日用品'
          i['orderData'].cargoDesc = []
          i['orderData'].weight = 1
        }
      }
      this.recman = list
      // if (this.newComName) {
      //   this.getPrice()
      // }
      // if (tag === 'popup') {
      //   this.availableCom4Brand('popup')
      // } else if (tag === 'autoSelect') { // 预选择
      //   this.availableCom4Brand('autoSelect')
      // }
    },
    getRecmanList(tag) {
      const list = this.clone(this.recman)
      let num = 0
      for (const i of list) {
        for (const k in i.orderData) {
          i[k] = i.orderData[k]
        }
        i.cargoDesc = i.orderData.cargoDesc?.join(',')
        // i.recxzq = `${i.province},${i.city},${i.district}`
        i.recxzq = i.xzqName
        i.recAddr = i.address
        i.recMobile = i.phone
        i.recTel = i.fixedPhone
        i.raddrid = i.id
        i.recName = i.name
        i.cargo = i.cargoInput ? i.cargoInput : i.cargo
        if (tag === 'price' && this.priceInfo.length) {
          i.disCountsId = this.priceInfo[num].discountsId || ''
          i.disCountsAmount = this.priceInfo[num].discountsAmount || ''
          i.predictPrice = this.priceInfo[num]?.costTotalPrice || ''
          i.firstWeightPrice = this.priceInfo[num]?.firstWeightPrice || 0
          i.additionalWeightPrice = this.priceInfo[num]?.overTotalPrice || 0
          i.additionalWeight = this.priceInfo[num]?.overWeight || 0
          i.additionalWeightUnitPrice = this.priceInfo[num]?.overWeightPrice || 0
          i.detailValinspayPrice = this.priceInfo[num]?.valinspayPrice || 0
        }
        num = num + 1
      }
      return JSON.stringify(list)
    },
    myComlist() { // 整理comlist
      const comlist = []
      const d = {
        com: this.nowCom.kuaidiCom || '',
        dispatchid: this.nowCom.dispatchid || '',
        sign: this.nowCom.sign || '',
        mktid: this.nowCom.mktid || ''
      }
      comlist.push(d)
      return JSON.stringify(comlist)
    },
    availableCom4Brand(tag) { //  快递公司列表
      return new Promise((resolve, rej) => {
        this.nowCom = {}
        this.isShowLoading = true
        // this.isExpressing = true
        // let loading = this.$loading('正在加载')
        const url = '/apicenter/order.do?method=availableCom4BrandBatch'
        if (tag === 'popup') {
          this.isShowNewCom = true
        }
        const params = {
          data: {
            saddrid: this.sendman.id || '',
            sendxzq: this.sendman.xzqName,
            sendAddr: this.sendman.address,
            latitude: this.new_lat,
            longitude: this.new_lon,
            recList: this.getRecmanList(),
            openOnline: 'Y',
            apiversion: 23
          }
        }
        this.$http.post(url, params).then((res) => {
          this.isShowLoading = false
          this.availableCom4BrandList = res.data || []
          if (tag === 'autoSelect') {
            this.autoChooseWithCom()
          } else {
            this.autoChoose()
          }
        }).catch(() => {
          this.isShowLoading = false
          this.nowCom = {}
          this.availableCom4BrandList = []
        }).finally(() => {
          // this.isExpressing = false
          resolve()
        })
      })
    },
    availableCom4BrandNew() {
      return new Promise((resolve, rej) => {
        // this.nowCom = {}
        this.isShowLoading = true
        // this.isExpressing = true
        // let loading = this.$loading('正在加载')
        const url = '/apicenter/order.do?method=availableCom4BrandBatch'
        const params = {
          data: {
            saddrid: this.sendman.id || '',
            sendxzq: this.sendman.xzqName,
            sendAddr: this.sendman.address,
            latitude: this.new_lat,
            longitude: this.new_lon,
            recList: this.getRecmanList(),
            openOnline: 'Y',
            apiversion: 23
          }
        }
        this.$http.post(url, params).then((res) => {
          this.isShowLoading = false
          this.availableCom4BrandList = res.data || []
          const index = this.availableCom4BrandList.findIndex((item)=>item.comService === this.nowComId)
          if (index === -1) {
            this.autoChoose()
          }
          else {
            this.nowCom = this.availableCom4BrandList[index]
            this.getPrice()
          }
        }).catch(() => {
          this.isShowLoading = false
          this.nowCom = {}
          this.availableCom4BrandList = []
        }).finally(() => {
          // this.isExpressing = false
          resolve()
        })
      })
    },
    checkCom(item) {
      return item.comService === this.nowComId
    },
    chooseCom(item) { //  勾选快递公司
      if (item.useable !== 'Y') {
        return
      }
      this.priceInfo = []
      this.nowCom = item
      this.priceNum = 4
      this.getPrice()
    },
    autoChooseWithCom() {
      for (let i = 0; i < this.availableCom4BrandList.length; i++) {
        if (this.availableCom4BrandList[i].useable === 'Y' && this.availableCom4BrandList[i].kuaidiCom === this.popCom.kuaidiCom) {
          this.nowCom = this.availableCom4BrandList[i]
          this.getPrice()
          break
        }
      }
      this.popCom = {}
    },
    autoChoose() { //  自动选择
      const index = this.availableCom4BrandList.findIndex(item => item.useable === 'Y')
      if (index > -1) {
        this.nowCom = this.availableCom4BrandList[index]
        this.getPrice()
      }
    },
    getPrice() {
      // this.$loading()
      const loading = this.$loading('正在加载')
      const sendman = this.sendman
      const url = '/apicenter/order.do?method=pfprice4Batch'
      const params = {
        data: {
          // sentxzq: sendman.province + sendman.city + sendman.district || '',
          sentxzq: sendman.xzqName,
          sentAddr: sendman.address || '',
          doortime: this.orderData.doortime || '',
          apiversion: 23,
          recList: this.getRecmanList(),
          comlist: this.myComlist()
        }
      }
      this.$http.post(url, params).then((r) => {
        this.priceInfo = r.data || []
      }).catch(() => {
        this.priceInfo = []
      }).finally(() => {
        // this.$hideLoading()
        loading.hide()
      })
    },
    showOriginPrice_com(item) {
      return item.linePrice && +item.costTotalPrice !== +item.linePrice
    },
    confirmCom() { //  关闭选择公司
      this.isShowNewCom = false
      this.queryFirstKuaidCom()
    },
    chooseComDone() { //  选择快递公司 点击完成
      // if (this.isExpressing) {
      //   return
      // }
     
    },
    async queryFirstKuaidCom() { //  预派单
      if (!this.nowComId) return
      this.setServiceTime(this.nowCom.serviceTime)
      if (this.nowCom.payment !== 'ALL' && this.choosePaywayIndex === 1) {
        this.choosePaywayIndex = 0
      }
      this.clearDoorTime()
      await this.getDoorTimeList()
      this.confirmDoor()
    },
    clearDoorTime() {
      this.orderData.doortime = ''
      this.indexArry = [0, 0]
      this.night = false
    },
    setServiceTime(time) {
      const date = new Date()
      const times = time ? time.split('-') : ''
      const timestr = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
      let serviceStr = ''
      if (timestr < times[0]) {
        serviceStr = `现在下单预计${times[0]}之后取件`
      } else if (timestr > times[1]) {
        serviceStr = `现在下单预计明天${times[0]}之后取件`
      }
      if (serviceStr) {
        this.serviceTimeTips = serviceStr
        this.serviceTimeTipsShow = true
        setTimeout(() => {
          this.serviceTimeTipsShow = false
        }, 2500)
      }
    },
    confirmDoor(tag) {
      const ddate = this.doordate[this.indexArry[0]]
      const dtime = this.doorTime[this.indexArry[1]].itemValue
      this.orderData.doortime = ddate + dtime
      this.doorModal = false
      if (tag !== 'dontGetPrice') {
        this.getPrice()
      }
    },
    showNewCom () {
      if (this.sendman.name && this.recman.length > 0) {
        this.isShowNewCom = true
        if (this.availableCom4BrandList.length === 0) {
          this.availableCom4Brand('popup')
        }
      } else {
        this.$toast('请先填写收寄件人地址')
      }
    },
    showDoorModal () {
      if (this.isEmptyObj(this.sendman) || this.recman.length === 0) {
        this.$toast('请先填写收寄件人信息')
      } else if (!this.newComName) {
        return this.$toast('请选择快递公司')
      } else {
        this.doorModal = true
        if (!this.orderData.doortime) {
          this.getDoorTimeList()
        }
      }
    },
    getDoorTimeList() {
      return new Promise((resolve, rej) => {
        this.resetInfo()
        const sendman = this.sendman
        const recman = this.recman
        const url = '/apicenter/kdmkt.do?method=queryDoorTimeList'
        const params = {
          data: {
            // platform: PROVIDER,
            platform: 'm',
            cargo: this.orderData.cargo,
            sendxzq: sendman.xzqName || '',
            sendAddr: sendman.address || '',
            recxzq: recman[0].xzqName || '',
            recAddr: recman[0].address || '',
            comlist: this.myComlist(),
            apiversion: 18
          }
        }
        this.$http.post(url, params).then((res) => {
          let doordate = []
          let doorTime = this.doorTime || []
          if (res.data.today?.length) {
            doordate = ['今天', '明天', '后天']
            doorTime = res.data.today
          } else {
            doordate = ['明天', '后天']
            doorTime = res.data.tomorrow
          }
          this.isExistMkt = res.data.isExistMkt
          this.doorTimelist = res.data
          this.doordate = doordate
          this.doorTime = doorTime
          this.nightTips = res.data.tips
          const columns = [
            {
              values: doordate,
              defaultIndex: 0
            },
            {
              values: this.getTimeArr(this.doorTime),
              defaultIndex: 0,
              className: 'myTime'
            },
          ]
          this.$set(this, 'columns', columns)
          resolve()
        }).catch(() => {})
      })
    },
    getTimeArr (arr) {
      let list = []
      for(let i of arr) {
        list.push(i.itemValue)
      }
      return list
    },
    bindDoorChange (e) {
      let p = false
      //当前选择index
      let indexValue = this.$refs.myTime.getIndexes()
      //当前选择的日期
      let ddate = this.doordate[indexValue[0]]
      //重置时间
      let doorTime = this.doorTime
      if (ddate == '明天' || ddate == '后天') {
        doorTime = this.doorTimelist.tomorrow || []
      } else {
        doorTime = this.doorTimelist.today || []
      }
      let night = false
      if (!this.isExistMkt && doorTime.length > indexValue[1] && doorTime[indexValue[1]].descr) {
        indexValue[1] = indexValue[1] - 1
        p = true
      } else if (this.isExistMkt && doorTime.length > indexValue[1] && doorTime[indexValue[1]].descr) {
        night = true
      }
      if (!p) {
        this.doorTime = doorTime
        this.indexArry = indexValue
        this.night = night
      } else { // 不这样做 无法渲染
        let a = doorTime
        this.doorTime = []
        this.$nextTick(()=> {
          this.doorTime = a
          this.indexArry = indexValue
          this.night = night
        })
      }
      this.columns[1].values = this.getTimeArr(this.doorTime)
      e.setIndexes(this.indexArry)
      this.$nextTick(()=> {
        const arr = this.indexArry
        arr[1] = e.getColumnIndex(1)
        e.setIndexes(arr)
      })
    },
    resetInfo() { // 清除时间
      this.doorTimelist = {}
      this.doordate = []
      this.doorTime = []
      this.choosePaywayIndex = 0
    },
    overText (str, num = 6) { // 超出字数
      if (str && str.length > num) {
        return str.substring(0, num) + '...'
      } else {
        return str
      }
    },
    isEmptyObj(obj) {
      return JSON.stringify(obj) === '{}'
    },
    clone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    selectPayway() { // 选择支付方式
      const payway = this.nowCom.payway
      if (payway === 0) { // 优选
        this.$toast({content: '该快递公司仅支持在线支付', time: 1000})
      } else if (payway === 1) { // 线下
        // this.showPaywayModal = true
        this.$toast({content: '该快递公司仅支持线下支付', time: 1000})
      } else if (payway === 3){ // 线上
        this.$toast({content: '该快递公司仅可使用支付宝免密支付下单', time: 1000})
      }
    },
    showProtocol (e) {
      this.protocolMeta.show = true
      this.protocolMeta.type = e
    },
    confirmProtocol () { // 点击同意寄件服务协议
      this.toggleProtocol(true)
      this.protocolMeta.show = false
      if (this.protocolMeta.type == 1) {
        this.submit()
      }
      if(this.protocolMeta.agree) {
        var d = new Date();
        localStorage.setItem('protocolMeta.agree', d.getTime());
      }
    },
    toggleProtocol (v) {
      this.protocolMeta.agree = typeof v == 'boolean' ? v : !this.protocolMeta.agree
    },
    getMoreOpt () {
      if (this.isEmptyObj(this.priceInfo)) {
        return this.$toast('请先选择快递公司')
      }
      this.payDetailModal = !this.payDetailModal
    },
    choosePayway (index) { // 下单优化 选择支付方式
      this.choosePaywayIndex = index
    },
    guessFee(list, recman) {
      let count = 0
      for (let i = 0; i < list.length; i++) {
        count += parseFloat(list[i].totalprice)
      }
      if (count.toFixed(2) < 0) {
        return '0.00'
      } else {
        return count.toFixed(2)
      }
    },
    totalPrice (a) {
      return ['jd', 'shunfeng'].indexOf(a) > -1 || false
    },
    overWeight (a) {
      return ['jd', 'shunfeng'].indexOf(a) > -1 ? false : true
    },
    showDetail(index) {
      this.$set(this.priceInfo[index], 'open', !this.priceInfo[index].open)
    },
    submit() {
      if (!this.sendman.name || this.recman.length === 0) {
        this.$toast('请填写收寄件人地址')
      } else if (!this.sendman.phone) {
        this.$toast('抱歉，暂不支持座机为寄件人联系方式，请填写正确的手机号码')
      } else if (!this.newComName) {
        this.$toast('请选择快递公司')
      } else if (!this.orderData.doortime) {
        this.$toast('请选择期望上门时间')
      } else if (!this.protocolMeta.agree) {
        this.$toast('请阅读并同意《快递100寄件服务与隐私协议》')
        this.showProtocol(1)
      } else {
        this.submitOrder()
      }
    },
    submitOrder() {
      let useAlipay = this.nowCom.kdbest === 'N' && this.alipayData.alipayBtn && ['0', '3'].includes(this.nowCom.payway + '')
      // 没有签约芝麻先亨，且需要使用芝麻先享或者选择的运力只能用芝麻先亨的，则提示开通芝麻先亨
      if (this.alipayData.alipaystatus != 1 && (useAlipay || this.nowCom.payway == '3') && !(this.nowCom.kdbest === 'Y')) {
        // this.openAlipayNow()
        this.showOpenAlipay = true
        return
      } else {
        this.toSubOrder()
      }
    },
    toSubOrder() {
      let loading = this.$loading('正在下单')
      let sendman = this.sendman
      const url = '/apicenter/order.do?method=submitOrderBatch4Kdest'
      let payway = this.nowCom.kdbest === 'N' && this.alipayData.alipayBtn && ['0', '3'].includes(this.nowCom.payway + '') ? 'ZHIFUBAOCONTRACT' : ''
      const params = {
        data: {
          platform: 'm',
          orderSource: this.orderSource,
          //收件人寄件人传参
          sendxzq: sendman.xzqName,
          sendAddr: sendman.address,
          sendName: sendman.name,
          sendMobile: sendman.phone || '',
          sendTel: sendman.fixedPhone || '',
          saddrid: sendman.id || '',
          comlist: this.myComlist(),
          recList: this.getRecmanList('price'),
          //经纬度
          latitude: this.sendman.latitude || '',
          longitude: this.sendman.longitude || '',
          //订单信息
          // cargo: this.orderData.cargo === '其他' ? this.orderData.cargoInput : this.orderData.cargo, 
          // weight: this.orderData.weight || 1,
          comment: this.orderData.comment,
          payway: payway,
          // stampid: '',
          // 下单优化
          // cargodesc: this.orderData.cargoDesc.toString(),
          gotaddr: this.sendman.address,
          doortime: this.orderData.doortime,
          // valinspay: this.orderData.valinspay || '',
          payment: this.choosePaywayIndex == '0' ? 'SHIPPER' : 'CONSIGNEE',
          apiversion: 23,
          remindId: this.remindId || '',
          inviteSign: this.inviteSign || '',
          uuid: this.generateUuidData,
          changeOrderAble: 'N'
        },
        handleFail: false
      }
      this.$http.post(url, params).then((res) => {
        // uni.setStorageSync('batchData', res.data)
        localStorage.setItem('batchData', JSON.stringify(res))
        // uni.redirectTo({
        //   url: `/pages/dispatch/batchSuccess?batchNo=${res.batchNo}&failcount=${res.failcount}&successCount=${res.successCount}`
        // })
        this.$router.push({
          name: "batchSuccess",
          query: {
            batchNo: res.batchNo,
            failcount: res.failcount,
            successCount: res.successCount
          }
        })
        this.clearAll()
      }).catch((res) => {
        setTimeout(()=> {
          this.$toast(res.message || '系统繁忙， 请稍后再试')
        }, 250)
      }).finally(() => {
        loading.hide()
      })
    },
    clearInfo () {
      setTimeout(()=> {
        this.sendman = {}
        this.recman = []
        const orderData = {
          cargo: '',
          weight: 1,
          cargoInput: '',
          picurl: '',
          cargoImgUrl: '',
          cargoDesc: [],
          comment: '',
          doortime: '',
          switchChecked: false
        }
        this.orderData = orderData
        // this.chooseComIndex = []
        this.availableCom4BrandList = []
        this.nowCom = {}
        this.priceInfo = {}
        this.generateUuid()
        this.payDetailModal = false
        this.choosePaywayIndex = 0
      }, 500)
    },
    async checkOpenAlipay() { // 检查开通状态
      await this.alipaysignstatus()
      if (this.alipayData.alipaystatus === '1') { // 已开通
        this.showAlipayBack = false
        this.toSubOrder()
      } else { // 未开通
        this.$toast({content: '未开通支付宝代扣，请重试', time: 1000})
        this.showAlipayBack = false
        setTimeout(()=> {
          this.showOpenAlipay = true
        }, 1000)
      }
    },
    closeAlipayback() {
      this.showAlipayBack = false
    },
    alipaysignstatus (tag) { // 查询支付宝签约状态
      let loading = this.$loading('正在查询授权结果')
      const url = '/apicenter/kdquerytools.do?method=alipaysignstatus'
      const params = {
        handleFail: false
      }
      return new Promise((resolve, reject)=>{
        this.$http.get(url, params).then((res)=> {
          this.alipayData.alipaystatus = res.data.status
          if (res.data.status === '1') {
            loading.hide()
            // this.showPayResult = false
          }
          resolve()
        }).catch(err=> {
          loading.hide()
          resolve()
        }).finally(() => {
          loading.hide()
          resolve()
        })
      })
    },
    openAlipayNow() { // 开通支付宝代扣
      const url = '/apicenter/kdquerytools.do?method=queryAlipaysign'
      const params = {
        handleFail: false,
      }
      this.$http.post(url, params).then((res)=> {
        const signStr = res.data || ''
        if (!signStr) {
          this.showOpenAlipay = false
          this.$toast({content: '开通失败', time: 1000})
          return
        }
        const schemeUrl = this.redirectToAlipay(signStr)
        // location.href = schemeUrl
        this.checkAlipayInstallation(schemeUrl,  ()=> {
          console.log("跳转成功");
          this.showOpenAlipay = false
          const handleVisibilityBack = async ()=> {
            if (document.visibilityState === 'visible') {
              // 页面重新获得焦点的逻辑处理
              console.log('页面重新获得焦点')
              await this.alipaysignstatus()
              if(this.alipayData.alipaystatus === '1') {
                this.toSubOrder()
              } else {
                this.showAlipayBack = true
              }
              document.removeEventListener('visibilitychange', handleVisibilityBack)
            }
          }
          document.addEventListener('visibilitychange', handleVisibilityBack)
        }, ()=> {
          this.$toast({content: '支付宝唤起失败', time: 1000})
          this.showOpenAlipay = false
          // this.availableCom4BrandOff()
        })
      })
    },
    redirectToAlipay(signStr, tag) {
      const enUrl = encodeURIComponent('https://openapi.alipay.com/gateway.do?' + signStr)
      const sc = 'alipays://platformapi/startapp?appId=20000067&url=' + enUrl // 开通免密支付 appId固定为20000067   
        // const schemeUrl = 'alipays://platformapi/startapp?appId=20000067&url=' + encodeURIComponent('https://render.alipay.com/p/yuyan/180020010000706007/index.html?signStr=' + encodeURIComponent(signStr));
        // window.location.href = schemeUrl;
      if (tag === 1) {
        return 'https://render.alipay.com/p/s/i/?scheme=' +  encodeURIComponent(schemeUrl)
      }
      return sc
    },
    checkAlipayInstallation(schemeUrl, successCallback, failCallback) {
      let timeout = 3000 // 设置超时时间为3秒
      let timer
  
      function clearTimer() {
        if (timer) {
          clearTimeout(timer)
          timer = null
        }
      }
      function handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
          clearTimer()
          if (successCallback) successCallback() // 执行成功逻辑
          window.removeEventListener('visibilitychange', handleVisibilityChange) // 移除事件监听器
        }
      }
      const checkWithLocation = ()=>{
        this.$toast("跳转中")
        window.top.location.href = schemeUrl
        timer = setTimeout(function() {
          // document.body.removeChild(iframe) // 移除iframe
          if(failCallback) failCallback() // 执行fail逻辑
          window.removeEventListener('visibilitychange', handleVisibilityChange); // 移除事件监听器
        }, timeout)

        window.addEventListener('visibilitychange', handleVisibilityChange) //页面隐藏即跳转成功
      }
      try {
        // checkWithIframe()
        checkWithLocation()
      } catch (error) {
        console.log(error)
      }
    },
  },
  activated () { // onShow
    this.generateUuid()
    if (!this.isEmptyObj(this.sendman)) {
      this.address2geo()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
    })
  },
  watch: {

  }
}