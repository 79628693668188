<template>
  <div class="wrap">
    <section class="add-box">
      <div class="slide-wrap">
        <div class="copy">
          <p>
            <textarea v-model="detail.reconize" class="text-l" placeholder="请将您复制的包含地址、联系方式的内容粘贴到此，即可智能识别。如：张三，13800138000，广东省深圳市南山区科技南十二路金蝶软件园"></textarea>
          </p>
        </div>
        <p>
          <label>姓&emsp;&emsp;名：</label><input v-model="detail.name" type="text" placeholder="请输入姓名">
        </p><p><label>手机号码：</label><input v-model="detail.mobile" type="text" placeholder="请输入手机号码"></p>
        <p v-if="!hideTel"><label>座机号码：</label><input v-model="detail.fixedPhone" type="text" placeholder="请输入座机号码（选填）"></p>
        <p class="link">
          <label>选择地区：</label><span class="input" :class="{placeholder:!detail.xzqName}" @click="showPicker">{{ (detail.xzqName || "").replace(/,/g, ' ') || '请选择地区' }}</span>
        </p>
      </div>
      <p><label>详细地址：</label><textarea v-model.trim="detail.addr" placeholder="请输入详细地址" ></textarea></p>
    </section>
    <div class="global-btn" @click="saveContact">
      {{confirmText}}
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import Region from "lib/region"
import util from "lib/util"
import  axios  from 'lib/axios'
const { $http } = axios

export default {
  name: "contactDetail",
  data() {
    return {
      detail: {
        xzqName: "",
        name: "",
        mobile: "",
        fixedPhone: "",
        address: ""
      },
      showAuto: false,
      autos: [],
      
    }
  },
  props: {
    detailProp: {
      type: Object,
      default: {
        xzqName: "",
        name: "",
        mobile: "",
        fixedPhone: "",
        addr: ""
      }
    },
    hideTel: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: "保存"
    },
    update: {
      type: Number,
      default: 0
    }
  },
  watch: {
    update: { 
      handler: function(){
        try {
          console.log("detailprop",this.detailprop);
          this.detail = this.detailProp
          this.$forceUpdate()
        } catch (error) {
          console.log(error);
        }
        
      },
      immediate: true
    }
  },
  methods: {
    validateContact() {
      var detail = this.detail
      var flag = false
      if (!detail.name) {
        this.$toast("姓名不能为空", 1500)
      } else if (this.hideTel && !detail.phone) {
        this.$toast("请填写手机号")
      } else if(!detail.phone && !detail.fixedPhone) {
        this.$toast("手机和座机至少填写一个")
      } else if (detail.phone && !/^1\d{10}$/.test(detail.phone)) {
        this.$toast("请输入正确的手机号码", 1500)
      } else if(detail.fixedPhone && !/^(\(\d{3,4}\)|\d{3,4}(-|\s)?)?\d{6,8}$/.test(detail.fixedPhone)){
        this.$toast("请输入正确座机号码", 1500)
      } else if (!detail.xzqName) {
        this.$toast("请选择行政区", 1500)
      } else if (!detail.address) {
        this.$toast('请填写正确的详细地址', 1500)
      } else {
        flag = true
      }
      return flag
    },
    saveContact() {
      // if (!this.validateContact()) return
      // if(this.detail.address.length < 4) {
      //   return this.$toast('详细地址不能少于4个字')
      // }
      // let detail = this.detail
      // let url 
      // var data = {
      //   type: 'MKTSENTCONTACT',
      //   name: detail.name,
      //   mobile: detail.phone || "",
      //   tel: detail.fixedPhone || "",
      //   province: detail.xzqName.split(',')[0],
      //   city: detail.xzqName.split(',')[1] || '',
      //   district: detail.xzqName.split(',')[2] || '',
      //   addr: detail.address,
      //   longitude: detail.longitude || '',
      //   latitude: detail.latitude || ''
      // }
      // if(detail.id  > 0){
      //   url = "/apicenter/kdmkt.do?method=exclusiveVisit&act=save"
      //   data.id = detail.id
      // } else {
      //   url = "/apicenter/kdmkt.do?method=exclusiveVisit&act=addnew"
      // }
      // this.loading = this.$loading("正在保存...")
      // this.$http.post(url, {
      //   data: data
      // }).then(result => {
      //   if(!this.detail.id) {
      //     this.detail.id = result.data[0].id
      //   }
      //   // 从列表已选择的地址进行编辑以及直接从寄件页面进来的需要选中地址
      //   if(this.from.query.selectId == this.detail.id || this.$route.params.tag) {
      //     this.$broadcastChannel.$emit("fillContact", this.detail, this.from.query.tag || this.$route.params.tag)
      //   }
      //   this.$router.go(-1)
      //   this.loadig.hide()
      // }).catch(() => {
      //   this.loading.hide()
      // })
      this.$emit('save',this.detail)
    },
    showPicker: function() {
      if (!this.detail.xzq) {
        this.region.value = []
        this.region.init();
      }
      this.region.show((this.detail.xzqName || "").split(","))
    },
    reconizeContact: function(text) {
      if (text || this.detail.reconize) {
        this.$http.post("/order/open/sent.do?method=infosplit", {
          data: {
            content: text || this.detail.reconize
          }
        }).then(result => {
          var data = result.data[0], detail = this.detail
          if(!text) {
            data.name && (detail.name = data.name)
            if(data.mobile && data.mobile.length) {
              if(/^1\d{10}$/.test(data.mobile[0])) {
                detail.phone = data.mobile[0]
                detail.fixedPhone = ""
              } else {
                detail.fixedPhone = data.mobile[0]
                detail.mobile = ""
              }
            }
            data.addr && (detail.address = data.addr)
          }
          data.xzq && data.xzq.fullName && (detail.xzqName = data.xzq.fullName)
          this.$forceUpdate()
        })
      }
    },
  },
  watch: {
    'detail.address': function() {
      // if(!this.detail.address || this.detail.address.indexOf(this.lastaddress) !== 0) {
      //   this.AutoComplete && this.searchAuto()
      // }
    },
    'detail.reconize': function() {
      // console.log('????')
      // this.rcgTimer && clearTimeout(this.rcgTimer)
      // this.rcgTimer = setTimeout(() => {
      //   this.reconizeContact()
      // }, 500)
    },
    "detail.xzqName": function(val) {
      var city = val && val.split(",")[1]
      this.AutoComplete && this.AutoComplete.setCity(city)
    }
  },
  mounted() {
    // const params = this.$route.params
    this.detail = this.detailProp
    this.region = new Region({
      others: null,
      level: 2,
      value: (this.detail.xzqName || "").split(","),
      confirm: result => {
        var str = []
        for (let i = 0, len = result.length; i < len; i++) {
          result[i] && (result[i].name) && str.push(result[i].name)
        }
        this.$set(this.detail, 'xzqName', str.join(","))
      },
    })
    util.map.plugin(['AMap.AutoComplete'], () => { //自动填充地址
      this.AutoComplete = new AMap.AutoComplete({
        citylimit: true
      })
    })
  },
  beforeDestroy() {
    this.region.hide()
  },
  // async beforeRouteEnter(to, from, next) {
  //   let source = to.params.source
  //   let detail = to.params.detail
  //   console.log(detail)
  //   // 解密
  //   if (detail.id && (detail.phone && detail.phone.indexOf('*') > -1) || (detail.fixedPhone && detail.fixedPhone.indexOf('*') > -1)) {
  //     try {
  //       const res = await $http.get('/pcweb/addressbook/querybaseaddressbook', {
  //         data: {
  //           addrId: detail.id,
  //           // location: 2,
  //           addrVersion: 0
  //         }
  //       })
  //       detail.phone = res.data.phone || ''
  //       detail.fixedPhone = res.data.fixedPhone || ''
  //     } catch (err) {
  //       console.log(err)
  //       detail.phone = ''
  //       detail.fixedPhone = ''
  //     }
  //   }
  //   next(vm => {
  //     if(source) {
  //       vm.source = source
  //       vm.tag = to.params.tag
  //       vm.detail = detail || {}
  //       vm.from = from
  //       vm.$nextTick(()=> {
  //         // vm.detail.reconize = ''
  //       })
  //     }
  //   })
  // }
}
</script>
<style>
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.copy {
  margin-bottom: 10px;
}
.copy .text-l {
  background-color: #efeff4;
}
.add-box {
  /* flex: 1; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.add-box .slide-wrap {
  -webkit-transition: all .4s;
  transition: all .4s;
  overflow: hidden;
  flex:0;
}
.add-box .slide-wrap.hide {
  min-height: 0;
}
.add-box h4 {
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: normal;
  color: #ff7800;
  background-color: #e9e9e9;
}
.add-box p {
  display: -webkit-box;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  background-color: #fff;
}
.add-box p label {
  display: block;
  width: 5rem;
  height: 2.625rem;
  line-height: 2.625rem;
  color: #888;
  text-align: justify;
  text-align-last: justify;
  padding-right: 1em;
}
.add-box p input,.add-box p .input {
  display: block;
  -webkit-box-flex: 1;
  padding: 0 0.25rem;
  height: 2.625rem;
  line-height: 2.625rem;
  color: #333;
  border: 1px solid #fff;
  border-bottom-color: #dedede;
}
.add-box p .input.placeholder {
  color: #aaa;
}
.add-box p textarea {
  display: block;
  -webkit-box-flex: 1;
  margin: 0.5rem 0;
  padding: 0.25rem;
  height: 3rem;
  line-height: 1.5rem;
  color: #5a5a5a;
  border-color: #dedede;
  resize: none;
}
.add-box p textarea.text-l {
  height: 5rem;
}
.add-box .link {
  position: relative;
}
.add-box .link:after {
  position: absolute;
  right: 12px;
  top: 17px;
  width: 6px;
  height: 6px;
  border-top: 1px solid #dedede;
  border-right: 1px solid #dedede;
  -webkit-transform: roate(45deg);
  transform: rotate(45deg);
  content: '';
}
.add-box .del {
  display: block;
  margin-top: 10px;
  padding-left: 1rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  font-size: 0.875rem;
  color: #ff0000;
  background-color: #fff;
}
.add-box .auto-list {
  flex: 1;
  padding: 0 1em;
  margin-top: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #FFF;
}
.auto-list  li {
  padding: .5em 0;
  border-bottom: 1px solid #dedede;
}
.auto-list small {
  color: #888;
}
</style>